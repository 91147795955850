<template>
  <div class="animated fadeIn">
    <div class="row">
      <!-- Início EMBED -->
    <div class="col-sm-12 col-md-12">
        <div class="card">
            <div class="card-header">
                Consultoria Web
            </div> 
            <div class="card-block">
              <div class='container'>
                <div class="row">
                  <div class="col-md-12" style="margin-top:20px;">
                  <p>Para ter acesso à plataforma, você precisa ter um login e senha a parte e fazer parte do plano que permite acesso ao mesmo. Solicite um acesso com a equipe ProLucro.<br>Para mais informações, <a href="https://www.prolucro.com.br" target="_blank">visite nosso site</a>.</p>
                  </div>
                  <div class="col-md-4"></div>
                  <div class="col-md-4" style="margin-bottom:20px;"><a class="btn btn-warning" :href="this.URLSuporte" target="_blank" style="color:white;">Acessar o sistema</a></div>
                  <div class="col-md-4"></div>
                <!--<div id="embed" class="embed-responsive embed-responsive-16by9">
                <iframe name="suporte1" id="suporte1" class="embed-responsive-item" :src="this.URLSuporte"></iframe>
                </div>-->
                </div>
            </div>
            </div>
        </div>
    </div>
    <!-- Fim EMBED CHEETAH -->
    </div><!--/.row-->
  </div>
</template>

<script>
export default {
  name: 'suporte',
  created: function () {
  },
  methods: {
    click () {
      // do nothing
    }
  }
}
</script>